@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    font-family: "Inter", sans-serif !important;
  }

  
.featured-list {
  overflow: hidden;
  user-select: none;
  --gap: 50px;
  display: flex;
  gap: 50px;
}

.featured-list div {

  flex-shrink: 0;
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;

  animation: horizontal 15s linear infinite;
}

.featured-list:hover div {
  animation-play-state: paused;
}

@keyframes horizontal  {
  to {
    transform: translateX(calc(-100% - 50px));
  }
}
